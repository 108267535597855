@import "variables";
@import "mixins";

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
    display: block;
}

blockquote {
    margin: 0 0 10px 0;
    padding: 10px 20px;
    border-left: 5px solid #E2E2E2;
    font-size: 18px;
    line-height: 22px;
}

figure {
    margin: 0;
    margin-bottom: 10px;
}

figcaption {
    margin-top: 5px;
    text-align: center;
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

button,
html input[type="button"],
input[type="reset"] {
    border: 0;
    cursor: pointer;
    -webkit-appearance: button;
}

audio,
canvas,
progress,
video {
    display: inline-block;
    vertical-align: baseline;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

[hidden],
template {
    display: none;
}

abbr[title] {
    border-bottom: 1px dotted;
}

b,
strong {
    font-weight: bold;
}

dfn {
    font-style: italic;
}

mark {
    background: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sup {
    top: -0.5em;
}

sub {
    bottom: -0.25em;
}

svg:not(:root) {
    overflow: hidden;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;
}

pre {
    overflow: auto;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
    color: inherit;
    font: inherit;
    margin: 0;
}

button {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html input[type="button"],
input[type="reset"] {
    -webkit-appearance: button;
    cursor: pointer;
}

button[disabled],
html input[disabled] {
    cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

input {
    line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: textfield;
    box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

input:not([type]):focus:not([readonly]),
input[type=text]:focus:not([readonly]),
input[type=password]:focus:not([readonly]),
input[type=email]:focus:not([readonly]),
input[type=url]:focus:not([readonly]),
input[type=time]:focus:not([readonly]),
input[type=date]:focus:not([readonly]),
input[type=datetime]:focus:not([readonly]),
input[type=datetime-local]:focus:not([readonly]),
input[type=tel]:focus:not([readonly]),
input[type=number]:focus:not([readonly]),
input[type=search]:focus:not([readonly]),
textarea.materialize-textarea:focus:not([readonly]) {
    border-color: $call-to-action-colour;
    box-shadow: 0 1px 0 0 $call-to-action-colour;
}

input:not([type]):focus:not([readonly]) + label,
input[type=text]:focus:not([readonly]) + label,
input[type=password]:focus:not([readonly]) + label,
input[type=email]:focus:not([readonly]) + label,
input[type=url]:focus:not([readonly]) + label,
input[type=time]:focus:not([readonly]) + label,
input[type=date]:focus:not([readonly]) + label,
input[type=datetime]:focus:not([readonly]) + label,
input[type=datetime-local]:focus:not([readonly]) + label,
input[type=tel]:focus:not([readonly]) + label,
input[type=number]:focus:not([readonly]) + label,
input[type=search]:focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
    color: $copy-colour;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    border: 0;
    padding: 0;
}

textarea {
    overflow: auto;
}

optgroup {
    font-weight: bold;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

td,
th {
    padding: 0;
}

[class^="w-icon-"],
[class*=" w-icon-"] {
    font-family: 'webflow-icons';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.w-icon-slider-right:before {
    content: "\e600";
}

.w-icon-slider-left:before {
    content: "\e601";
}

.w-icon-nav-menu:before {
    content: "\e602";
}

.w-icon-arrow-down:before,
.w-icon-dropdown-toggle:before {
    content: "\e603";
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
}

html.w-mod-touch * {
    background-attachment: scroll !important;
}

.w-block {
    display: block;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}

.w-clearfix:before,
.w-clearfix:after {
    content: " ";
    display: table;
}

.w-clearfix:after {
    clear: both;
}

.w-preserve-3d {
    transform-style: preserve-3d;
}

.w-hidden {
    display: none;
}

.w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #3898EC;
    color: white;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0;
}

input.w-button {
    -webkit-appearance: button;
}

html[data-w-dynpage] [data-w-cloak] {
    color: transparent !important;
}

.w-webflow-badge,
.w-webflow-badge * {
    position: static;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    z-index: auto;
    display: block;
    visibility: visible;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    box-sizing: border-box;
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    margin: 0;
    padding: 0;
    float: none;
    clear: none;
    border: 0 none transparent;
    border-radius: 0;
    background: none;
    background-image: none;
    background-position: 0% 0%;
    background-size: auto auto;
    background-repeat: repeat;
    background-origin: padding-box;
    background-clip: border-box;
    background-attachment: scroll;
    background-color: transparent;
    box-shadow: none;
    opacity: 1.0;
    transform: none;
    transition: none;
    direction: ltr;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-variant: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    text-decoration: inherit;
    text-indent: 0;
    text-transform: inherit;
    list-style-type: disc;
    text-shadow: none;
    font-smoothing: auto;
    vertical-align: baseline;
    cursor: inherit;
    white-space: inherit;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
}

.w-webflow-badge {
    position: fixed !important;
    display: inline-block !important;
    visibility: visible !important;
    opacity: 1 !important;
    z-index: 2147483647 !important;
    top: auto !important;
    right: 12px !important;
    bottom: 12px !important;
    left: auto !important;
    color: #AAADB0 !important;
    background-color: #fff !important;
    border-radius: 3px !important;
    padding: 6px 8px 6px 6px !important;
    font-size: 12px !important;
    opacity: 1.0 !important;
    line-height: 14px !important;
    text-decoration: none !important;
    transform: none !important;
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    white-space: nowrap;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.w-webflow-badge > img {
    display: inline-block !important;
    visibility: visible !important;
    opacity: 1 !important;
    vertical-align: middle !important;
}

.w-list-unstyled {
    padding-left: 0;
    list-style: none;
}

.w-embed:before,
.w-embed:after {
    content: " ";
    display: table;
}

.w-embed:after {
    clear: both;
}

.w-video {
    width: 100%;
    position: relative;
    padding: 0;
}

.w-video iframe,
.w-video object,
.w-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.w-form {
    margin: 0 0 15px;
}

.w-form-done {
    padding: 20px;
    text-align: center;
    background-color: #dddddd;
}

.w-form-fail {
    display: none;
    margin-top: 10px;
    padding: 10px;
    background-color: #ffdede;
}

.w-input:-moz-placeholder,
.w-select:-moz-placeholder {
    color: #999;
}

.w-input::-moz-placeholder,
.w-select::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.w-input:-ms-input-placeholder,
.w-select:-ms-input-placeholder {
    color: #999;
}

.w-input::-webkit-input-placeholder,
.w-select::-webkit-input-placeholder {
    color: #999;
}

.w-input:focus,
.w-select:focus {
    border-color: #3898EC;
    outline: 0;
}

.w-input[disabled],
.w-select[disabled],
.w-input[readonly],
.w-select[readonly],
fieldset[disabled] .w-input,
fieldset[disabled] .w-select {
    cursor: not-allowed;
    background-color: #eeeeee;
}

textarea.w-input,
textarea.w-select {
    height: auto;
}

.w-select {
    background-image: -webkit-linear-gradient(white 0%, #f3f3f3 100%);
    background-image: linear-gradient(white 0%, #f3f3f3 100%);
}

.w-select[multiple] {
    height: auto;
}

.w-form-label {
    display: inline-block;
    cursor: pointer;
    font-weight: normal;
    margin-bottom: 0px;
}

.w-checkbox,
.w-radio {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px;
}

.w-checkbox:before,
.w-radio:before,
.w-checkbox:after,
.w-radio:after {
    content: " ";
    display: table;
}

.w-checkbox:after,
.w-radio:after {
    clear: both;
}

.w-checkbox-input,
.w-radio-input {
    margin: 4px 0 0;
    margin-top: 1px \9
;
    line-height: normal;
    float: left;
    margin-left: -20px;
}

.w-radio-input {
    margin-top: 3px;
}

.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px;
}

.w-container:before,
.w-container:after {
    content: " ";
    display: table;
}

.w-container:after {
    clear: both;
}

.w-container .w-row {
    margin-left: -10px;
    margin-right: -10px;
}

.w-row:before,
.w-row:after {
    content: " ";
    display: table;
}

.w-row:after {
    clear: both;
}

.w-row .w-row {
    margin-left: 0;
    margin-right: 0;
}

.w-col {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px;
}

.w-col .w-col {
    padding-left: 0;
    padding-right: 0;
}

.w-col-1 {
    width: 8.33333333%;
}

.w-col-2 {
    width: 16.66666667%;
}

.w-col-3 {
    width: 25%;
}

.w-col-4 {
    width: 33.33333333%;
}

.w-col-5 {
    width: 41.66666667%;
}

.w-col-6 {
    width: 50%;
}

.w-col-7 {
    width: 58.33333333%;
}

.w-col-8 {
    width: 66.66666667%;
}

.w-col-9 {
    width: 75%;
}

.w-col-10 {
    width: 83.33333333%;
}

.w-col-11 {
    width: 91.66666667%;
}

.w-col-12 {
    width: 100%;
}

.w-hidden-main {
    display: none !important;
}

@media screen and (max-width: 991px) {
    .w-container {
        max-width: 728px;
    }
    .w-hidden-main {
        display: inherit !important;
    }
    .w-hidden-medium {
        display: none !important;
    }
    .w-col-medium-1 {
        width: 8.33333333%;
    }
    .w-col-medium-2 {
        width: 16.66666667%;
    }
    .w-col-medium-3 {
        width: 25%;
    }
    .w-col-medium-4 {
        width: 33.33333333%;
    }
    .w-col-medium-5 {
        width: 41.66666667%;
    }
    .w-col-medium-6 {
        width: 50%;
    }
    .w-col-medium-7 {
        width: 58.33333333%;
    }
    .w-col-medium-8 {
        width: 66.66666667%;
    }
    .w-col-medium-9 {
        width: 75%;
    }
    .w-col-medium-10 {
        width: 83.33333333%;
    }
    .w-col-medium-11 {
        width: 91.66666667%;
    }
    .w-col-medium-12 {
        width: 100%;
    }
    .w-col-stack {
        width: 100%;
        left: auto;
        right: auto;
    }
}

@media screen and (max-width: 767px) {
    .w-hidden-main {
        display: inherit !important;
    }
    .w-hidden-medium {
        display: inherit !important;
    }
    .w-hidden-small {
        display: none !important;
    }
    .w-row,
    .w-container .w-row {
        margin-left: 0;
        margin-right: 0;
    }
    .w-col {
        width: 100%;
        left: auto;
        right: auto;
    }
    .w-col-small-1 {
        width: 8.33333333%;
    }
    .w-col-small-2 {
        width: 16.66666667%;
    }
    .w-col-small-3 {
        width: 25%;
    }
    .w-col-small-4 {
        width: 33.33333333%;
    }
    .w-col-small-5 {
        width: 41.66666667%;
    }
    .w-col-small-6 {
        width: 50%;
    }
    .w-col-small-7 {
        width: 58.33333333%;
    }
    .w-col-small-8 {
        width: 66.66666667%;
    }
    .w-col-small-9 {
        width: 75%;
    }
    .w-col-small-10 {
        width: 83.33333333%;
    }
    .w-col-small-11 {
        width: 91.66666667%;
    }
    .w-col-small-12 {
        width: 100%;
    }
}

@media screen and (max-width: 479px) {
    .w-container {
        max-width: none;
    }
    .w-hidden-main {
        display: inherit !important;
    }
    .w-hidden-medium {
        display: inherit !important;
    }
    .w-hidden-small {
        display: inherit !important;
    }
    .w-hidden-tiny {
        display: none !important;
    }
    .w-col {
        width: 100%;
    }
    .w-col-tiny-1 {
        width: 8.33333333%;
    }
    .w-col-tiny-2 {
        width: 16.66666667%;
    }
    .w-col-tiny-3 {
        width: 25%;
    }
    .w-col-tiny-4 {
        width: 33.33333333%;
    }
    .w-col-tiny-5 {
        width: 41.66666667%;
    }
    .w-col-tiny-6 {
        width: 50%;
    }
    .w-col-tiny-7 {
        width: 58.33333333%;
    }
    .w-col-tiny-8 {
        width: 66.66666667%;
    }
    .w-col-tiny-9 {
        width: 75%;
    }
    .w-col-tiny-10 {
        width: 83.33333333%;
    }
    .w-col-tiny-11 {
        width: 91.66666667%;
    }
    .w-col-tiny-12 {
        width: 100%;
    }
}

.w-widget {
    position: relative;
}

.w-widget-map {
    width: 100%;
    height: 400px;
}

.w-widget-map label {
    width: auto;
    display: inline;
}

.w-widget-map img {
    max-width: inherit;
}

.w-widget-map .gm-style-iw {
    width: 90% !important;
    height: auto !important;
    top: 7px !important;
    left: 6% !important;
    display: inline;
    text-align: center;
    overflow: hidden;
}

.w-widget-map .gm-style-iw + div {
    display: none;
}

.w-widget-twitter {
    overflow: hidden;
}

.w-widget-twitter-count-shim {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 28px;
    height: 20px;
    text-align: center;
    background: white;
    border: #758696 solid 1px;
    border-radius: 3px;
}

.w-widget-twitter-count-shim * {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.w-widget-twitter-count-shim .w-widget-twitter-count-inner {
    position: relative;
    font-size: 15px;
    line-height: 12px;
    text-align: center;
    color: #999;
    font-family: serif;
}

.w-widget-twitter-count-shim .w-widget-twitter-count-clear {
    position: relative;
    display: block;
}

.w-widget-twitter-count-shim.w--large {
    width: 36px;
    height: 28px;
    margin-left: 7px;
}

.w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 18px;
}

.w-widget-twitter-count-shim:not(.w--vertical) {
    margin-left: 5px;
    margin-right: 8px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large {
    margin-left: 6px;
}

.w-widget-twitter-count-shim:not(.w--vertical):before,
.w-widget-twitter-count-shim:not(.w--vertical):after {
    top: 50%;
    left: 0;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.w-widget-twitter-count-shim:not(.w--vertical):before {
    border-color: rgba(117, 134, 150, 0);
    border-right-color: #5d6c7b;
    border-width: 4px;
    margin-left: -9px;
    margin-top: -4px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
    border-width: 5px;
    margin-left: -10px;
    margin-top: -5px;
}

.w-widget-twitter-count-shim:not(.w--vertical):after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: white;
    border-width: 4px;
    margin-left: -8px;
    margin-top: -4px;
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
    border-width: 5px;
    margin-left: -9px;
    margin-top: -5px;
}

.w-widget-twitter-count-shim.w--vertical {
    width: 61px;
    height: 33px;
    margin-bottom: 8px;
}

.w-widget-twitter-count-shim.w--vertical:before,
.w-widget-twitter-count-shim.w--vertical:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.w-widget-twitter-count-shim.w--vertical:before {
    border-color: rgba(117, 134, 150, 0);
    border-top-color: #5d6c7b;
    border-width: 5px;
    margin-left: -5px;
}

.w-widget-twitter-count-shim.w--vertical:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: white;
    border-width: 4px;
    margin-left: -4px;
}

.w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 22px;
}

.w-widget-twitter-count-shim.w--vertical.w--large {
    width: 76px;
}

.w-widget-gplus {
    overflow: hidden;
}

.w-background-video {
    position: relative;
    overflow: hidden;
    height: 500px;
    color: white;
}

.w-background-video > video {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    margin: auto;
    min-width: 100%;
    min-height: 100%;
    z-index: -100;
}

.w-slider {
    position: relative;
    height: 300px;
    text-align: center;
    background: #dddddd;
    clear: both;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
}

.w-slider-mask {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    left: 0;
    right: 0;
    height: 100%;
    white-space: nowrap;
}

.w-slide {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: left;
}

.w-slider-nav {
    position: absolute;
    z-index: 2;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding-top: 10px;
    height: 40px;
    text-align: center;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
}

.w-slider-nav.w-round > div {
    border-radius: 100%;
}

.w-slider-nav.w-num > div {
    width: auto;
    height: auto;
    padding: 0.2em 0.5em;
    font-size: inherit;
    line-height: inherit;
}

.w-slider-nav.w-shadow > div {
    box-shadow: 0 0 3px rgba(51, 51, 51, 0.4);
}

.w-slider-nav-invert {
    color: #fff;
}

.w-slider-nav-invert > div {
    background-color: rgba(34, 34, 34, 0.4);
}

.w-slider-nav-invert > div.w-active {
    background-color: #222;
}

.w-slider-dot {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: rgba(255, 255, 255, 0.4);
    cursor: pointer;
    margin: 0 3px 0.5em;
    transition: background-color 100ms, color 100ms;
}

.w-slider-dot.w-active {
    background-color: #fff;
}

.w-slider-arrow-left,
.w-slider-arrow-right {
    position: absolute;
    width: 80px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: pointer;
    overflow: hidden;
    color: white;
    font-size: 40px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.w-slider-arrow-left [class^="w-icon-"],
.w-slider-arrow-right [class^="w-icon-"],
.w-slider-arrow-left [class*=" w-icon-"],
.w-slider-arrow-right [class*=" w-icon-"] {
    position: absolute;
}

.w-slider-arrow-left {
    z-index: 3;
    right: auto;
}

.w-slider-arrow-right {
    z-index: 4;
    left: auto;
}

.w-icon-slider-left,
.w-icon-slider-right {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1em;
    height: 1em;
}

.w-dropdown {
    display: inline-block;
    position: relative;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    z-index: 900;
}

.w-dropdown-btn,
.w-dropdown-toggle,
.w-dropdown-link {
    position: relative;
    vertical-align: top;
    text-decoration: none;
    color: #222222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap;
}

.w-dropdown-toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    cursor: pointer;
    padding-right: 40px;
}

.w-dropdown-toggle:focus {
    outline: 0;
}

.w-icon-dropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    margin-right: 20px;
    width: 1em;
    height: 1em;
}

.w-dropdown-list {
    position: absolute;
    background: #dddddd;
    display: none;
    min-width: 100%;
}

.w-dropdown-list.w--open {
    display: block;
}

.w-dropdown-link {
    padding: 10px 20px;
    display: block;
    color: #222222;
}

.w-dropdown-link.w--current {
    color: #0082f3;
}

.w-nav[data-collapse="all"] .w-dropdown,
.w-nav[data-collapse="all"] .w-dropdown-toggle {
    display: block;
}

.w-nav[data-collapse="all"] .w-dropdown-list {
    position: static;
}

@media screen and (max-width: 991px) {
    .w-nav[data-collapse="medium"] .w-dropdown,
    .w-nav[data-collapse="medium"] .w-dropdown-toggle {
        display: block;
    }
    .w-nav[data-collapse="medium"] .w-dropdown-list {
        position: static;
    }
}

@media screen and (max-width: 767px) {
    .w-nav[data-collapse="small"] .w-dropdown,
    .w-nav[data-collapse="small"] .w-dropdown-toggle {
        display: block;
    }
    .w-nav[data-collapse="small"] .w-dropdown-list {
        position: static;
    }
    .w-nav-brand {
        padding-left: 10px;
    }
}

@media screen and (max-width: 479px) {
    .w-nav[data-collapse="tiny"] .w-dropdown,
    .w-nav[data-collapse="tiny"] .w-dropdown-toggle {
        display: block;
    }
    .w-nav[data-collapse="tiny"] .w-dropdown-list {
        position: static;
    }
}

.w-lightbox-backdrop {
    color: #000;
    cursor: auto;
    font-family: serif;
    font-size: medium;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    letter-spacing: normal;
    line-height: normal;
    list-style: disc;
    text-align: start;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    visibility: visible;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    font-family: "Helvetica Neue", Helvetica, Ubuntu, "Segoe UI", Verdana, sans-serif;
    font-size: 17px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    background: rgba(0, 0, 0, 0.9);
    z-index: 2000;
    outline: 0;
    /* 1 */
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-transform: translate(0, 0);
    /* 2 */
}

/**
 * Neat trick to bind the rubberband effect to our canvas instead of the whole
 * document on iOS. It also prevents a bug that causes the document underneath to scroll.
 */
.w-lightbox-backdrop,
.w-lightbox-container {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

.w-lightbox-content {
    position: relative;
    height: 100vh;
    overflow: hidden;
}

.w-lightbox-view {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0;
}

.w-lightbox-view:before {
    content: "";
    height: 100vh;
}

/* .w-lightbox-content */
.w-lightbox-group,
.w-lightbox-group .w-lightbox-view,
.w-lightbox-group .w-lightbox-view:before {
    height: 86vh;
}

.w-lightbox-frame,
.w-lightbox-view:before {
    display: inline-block;
    vertical-align: middle;
}

/*
 * 1. Remove default margin set by user-agent on the <figure> element.
 */
.w-lightbox-figure {
    position: relative;
    margin: 0;
    /* 1 */
}

.w-lightbox-group .w-lightbox-figure {
    cursor: pointer;
}

/**
 * IE adds image dimensions as width and height attributes on the IMG tag,
 * but we need both width and height to be set to auto to enable scaling.
 */
.w-lightbox-img {
    width: auto;
    height: auto;
    max-width: none;
}

/**
 * 1. Reset if style is set by user on "All Images"
 */
.w-lightbox-image {
    display: block;
    float: none;
    /* 1 */
    max-width: 100vw;
    max-height: 100vh;
}

.w-lightbox-group .w-lightbox-image {
    max-height: 86vh;
}

.w-lightbox-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: .5em 1em;
    background: rgba(0, 0, 0, 0.4);
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.w-lightbox-embed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.w-lightbox-control {
    position: absolute;
    top: 0;
    width: 4em;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
}

.w-lightbox-left {
    display: none;
    bottom: 0;
    left: 0;
    /* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-20 0 24 40" width="24" height="40"><g transform="rotate(45)"><path d="m0 0h5v23h23v5h-28z" opacity=".4"/><path d="m1 1h3v23h23v3h-26z" fill="#fff"/></g></svg> */
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==");
}

.w-lightbox-right {
    display: none;
    right: 0;
    bottom: 0;
    /* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 24 40" width="24" height="40"><g transform="rotate(45)"><path d="m0-0h28v28h-5v-23h-23z" opacity=".4"/><path d="m1 1h26v26h-3v-23h-23z" fill="#fff"/></g></svg> */
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+");
}

/*
 * Without specifying the with and height inside the SVG, all versions of IE render the icon too small.
 * The bug does not seem to manifest itself if the elements are tall enough such as the above arrows.
 * (http://stackoverflow.com/questions/16092114/background-size-differs-in-internet-explorer)
 */
.w-lightbox-close {
    right: 0;
    height: 2.6em;
    /* <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 0 18 17" width="18" height="17"><g transform="rotate(45)"><path d="m0 0h7v-7h5v7h7v5h-7v7h-5v-7h-7z" opacity=".4"/><path d="m1 1h7v-7h3v7h7v3h-7v7h-3v-7h-7z" fill="#fff"/></g></svg> */
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=");
    background-size: 18px;
}

/**
 * 1. All IE versions add extra space at the bottom without this.
 */
.w-lightbox-strip {
    padding: 0 1vh;
    line-height: 0;
    /* 1 */
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
}

/*
 * 1. We use content-box to avoid having to do `width: calc(10vh + 2vw)`
 *    which doesn’t work in Safari anyway.
 * 2. Chrome renders images pixelated when switching to GPU. Making sure
 *    the parent is also rendered on the GPU (by setting translate3d for
 *    example) fixes this behavior.
 */
.w-lightbox-item {
    display: inline-block;
    width: 10vh;
    padding: 2vh 1vh;
    box-sizing: content-box;
    /* 1 */
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0);
    /* 2 */
}

.w-lightbox-active {
    opacity: .3;
}

.w-lightbox-thumbnail {
    position: relative;
    height: 10vh;
    background: #222;
    overflow: hidden;
}

.w-lightbox-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0;
}

.w-lightbox-thumbnail .w-lightbox-tall {
    top: 50%;
    width: 100%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}

.w-lightbox-thumbnail .w-lightbox-wide {
    left: 50%;
    height: 100%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
}

/*
 * Spinner
 *
 * Absolute pixel values are used to avoid rounding errors that would cause
 * the white spinning element to be misaligned with the track.
 */
.w-lightbox-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border: 5px solid rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    -webkit-animation: spin .8s infinite linear;
    animation: spin .8s infinite linear;
}

.w-lightbox-spinner:after {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 3px solid transparent;
    border-bottom-color: #fff;
    border-radius: 50%;
}

/*
 * Utility classes
 */
.w-lightbox-hide {
    display: none;
}

.w-lightbox-noscroll {
    overflow: hidden;
}

@media (min-width: 768px) {
    .w-lightbox-content {
        height: 96vh;
        margin-top: 2vh;
    }
    .w-lightbox-view,
    .w-lightbox-view:before {
        height: 96vh;
    }
    /* .w-lightbox-content */
    .w-lightbox-group,
    .w-lightbox-group .w-lightbox-view,
    .w-lightbox-group .w-lightbox-view:before {
        height: 84vh;
    }
    .w-lightbox-image {
        max-width: 96vw;
        max-height: 96vh;
    }
    .w-lightbox-group .w-lightbox-image {
        max-width: 82.3vw;
        max-height: 84vh;
    }
    .w-lightbox-left,
    .w-lightbox-right {
        display: block;
        opacity: .5;
    }
    .w-lightbox-close {
        opacity: .8;
    }
    .w-lightbox-control:hover {
        opacity: 1;
    }
}

.w-lightbox-inactive,
.w-lightbox-inactive:hover {
    opacity: 0;
}

.w-richtext:before,
.w-richtext:after {
    content: " ";
    display: table;
}

.w-richtext:after {
    clear: both;
}

.w-richtext ol,
.w-richtext ul {
    overflow: hidden;
}

.w-richtext .w-richtext-figure-selected[data-rt-type="video"] div:before {
    outline: 2px solid #2895f7;
}

.w-richtext .w-richtext-figure-selected[data-rt-type="image"] div {
    outline: 2px solid #2895f7;
}

.w-richtext figure[data-rt-type="video"] > div:before {
    content: '';
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.w-richtext figure {
    position: relative;
    max-width: 60%;
}

.w-richtext figure > div:before,
.w-richtext figure img {
    cursor: default !important;
}

.w-richtext figure img {
    width: 100%;
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
    opacity: 0.6;
}

.w-richtext figure div {
    /* fix incorrectly sized selection border in the data manager */
    font-size: 0px;
    color: transparent;
}

.w-richtext figure[data-rt-type="image"] {
    display: table;
}

.w-richtext figure[data-rt-type="image"] > div {
    display: inline-block;
}

.w-richtext figure[data-rt-type="image"] > figcaption {
    display: table-caption;
    caption-side: bottom;
}

.w-richtext figure[data-rt-type="video"] {
    width: 60%;
    height: 0;
}

.w-richtext figure[data-rt-type="video"] iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.w-richtext figure[data-rt-type="video"] > div {
    width: 100%;
}

.w-richtext figure.w-richtext-align-center {
    margin-right: auto;
    margin-left: auto;
    clear: both;
}

.w-richtext figure.w-richtext-align-center[data-rt-type="image"] > div {
    max-width: 100%;
}

.w-richtext figure.w-richtext-align-normal {
    clear: both;
}

.w-richtext figure.w-richtext-align-fullwidth {
    width: 100%;
    text-align: center;
    clear: both;
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.w-richtext figure.w-richtext-align-fullwidth > div {
    display: inline-block;
    /* padding-bottom is used for aspect ratios in video figures
        we want the div to inherit that so hover/selection borders in the designer-canvas
        fit right*/
    padding-bottom: inherit;
}

.w-richtext figure.w-richtext-align-fullwidth > figcaption {
    display: block;
}

.w-richtext figure.w-richtext-align-floatleft {
    float: left;
    margin-right: 15px;
    clear: none;
}

.w-richtext figure.w-richtext-align-floatright {
    float: right;
    margin-left: 15px;
    clear: none;
}

.w-nav {
    position: relative;
    background: #dddddd;
    z-index: 1000;
}

.w-nav:before,
.w-nav:after {
    content: " ";
    display: table;
}

.w-nav:after {
    clear: both;
}

.w-nav-brand {
    position: relative;
    float: left;
    text-decoration: none;
    color: #333333;
}

.w-nav-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #222222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
}

.w-nav-link.w--current {
    color: #0082f3;
}

.w-nav-menu {
    position: relative;
    float: right;
}

.w--nav-menu-open {
    display: block !important;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #C8C8C8;
    text-align: center;
    overflow: visible;
    min-width: 200px;
}

.w--nav-link-open {
    display: block;
    position: relative;
}

.w-nav-overlay {
    position: absolute;
    overflow: hidden;
    display: none;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
}

.w-nav-overlay .w--nav-menu-open {
    top: 0;
}

.w-nav[data-animation="over-left"] .w-nav-overlay {
    width: auto;
}

.w-nav[data-animation="over-left"] .w-nav-overlay,
.w-nav[data-animation="over-left"] .w--nav-menu-open {
    right: auto;
    z-index: 1;
    top: 0;
}

.w-nav[data-animation="over-right"] .w-nav-overlay {
    width: auto;
}

.w-nav[data-animation="over-right"] .w-nav-overlay,
.w-nav[data-animation="over-right"] .w--nav-menu-open {
    left: auto;
    z-index: 1;
    top: 0;
}

.w-nav-button {
    position: relative;
    float: right;
    padding: 18px;
    font-size: 24px;
    display: none;
    cursor: pointer;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.w-nav-button.w--open {
    background-color: #C8C8C8;
    color: white;
}

.w-nav[data-collapse="all"] .w-nav-menu {
    display: none;
}

.w-nav[data-collapse="all"] .w-nav-button {
    display: block;
}

@media screen and (max-width: 991px) {
    .w-nav[data-collapse="medium"] .w-nav-menu {
        display: none;
    }
    .w-nav[data-collapse="medium"] .w-nav-button {
        display: block;
    }
}

@media screen and (max-width: 767px) {
    .w-nav[data-collapse="small"] .w-nav-menu {
        display: none;
    }
    .w-nav[data-collapse="small"] .w-nav-button {
        display: block;
    }
    .w-nav-brand {
        padding-left: 10px;
    }
}

@media screen and (max-width: 479px) {
    .w-nav[data-collapse="tiny"] .w-nav-menu {
        display: none;
    }
    .w-nav[data-collapse="tiny"] .w-nav-button {
        display: block;
    }
}

.w-tabs {
    position: relative;
}

.w-tabs:before,
.w-tabs:after {
    content: " ";
    display: table;
}

.w-tabs:after {
    clear: both;
}

.w-tab-menu {
    position: relative;
}

.w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 9px 30px;
    text-align: left;
    cursor: pointer;
    color: #222222;
    background-color: #dddddd;
}

.w-tab-link.w--current {
    background-color: #C8C8C8;
}

.w-tab-content {
    position: relative;
    display: block;
    overflow: hidden;
}

.w-tab-pane {
    position: relative;
    display: none;
}

.w--tab-active {
    display: block;
}

@media screen and (max-width: 479px) {
    .w-tab-link {
        display: block;
    }
}

.w-ix-emptyfix:after {
    content: "";
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.w-dyn-empty {
    padding: 10px;
    background-color: #dddddd;
}

.w-dyn-bind-empty {
    display: none !important;
}

.w-condition-invisible {
    display: none !important;
}